import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { InnerContainer } from 'containers/InnerContainer'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Navigation from 'components/Header/Navigation'
import PlayNow from 'components/Header/PlayNow'
import { ButtonMUI } from 'components/ButtonMUI'

import { cloudinary, staticMultimedia, socialLinks, TStaticMultimedia } from 'utils'

import UserIcon from 'assets/images/user.svg'
import styles from './styles.module.scss'
import { quality } from '@cloudinary/url-gen/actions/delivery'
import { auto } from '@cloudinary/url-gen/qualifiers/quality'


interface IProps {
}

const names = ['authencity', 'discord', 'twitter', 'medium']
const headerSocialLinks = socialLinks.filter(link => names.some(name => name === link.name))

const Header: FC<IProps> = observer(props => {
  const { userStore, accountStore } = useStore()
  const { isAuthenticated } = userStore
  const navigate = useNavigate()

  const redirectToProfilePage = () => {
    navigate('/profile')
  }

  const redirectToLoginPage = () => {
    navigate('/auth')
  }

  const userIcon = isAuthenticated
    ? null
    : <img style={{ width: '13px', height: '16px' }} src={UserIcon} alt="profile"/>

  const userNickname = accountStore.account
    ? <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{accountStore.account.NickName}</div>
    : 'Log Out'

  return (
    <header className={styles.header}>
      <InnerContainer>
        <div className={styles.box}>
          <Link className={styles.logo} to={'/'}>
            <img src={staticMultimedia.logo} alt="logo"/>
          </Link>

          <Link className={styles.logo} to={'https://store.somniumspace.com/'} target="_blank" rel="noopener noreferrer">
            <img src={staticMultimedia.shop} alt="shop"/>
          </Link>

          <Navigation/>

          <div className={styles.socialBox}>
            {headerSocialLinks.map(item => (
              <Link to={item.link} target="_blank" rel="noopener noreferrer" key={item.name}>
                <img src={staticMultimedia[item.name as TStaticMultimedia]} alt={item.name}
                     className={styles.socialIcon}/>
              </Link>
            ))}
          </div>

          <div className={styles.buttonBox}>
            <ButtonMUI styleType="blue"
                       type="button"
                       onClick={isAuthenticated ? redirectToProfilePage : redirectToLoginPage}
                       startIcon={userIcon}
                       sx={{
                         '&&': {
                           padding: '5px 15px',
                           fontSize: '0.875rem',
                           height: '36px',
                           width: '115px'
                         }
                         // "&.button": { fontSize: '0.875rem' } //if some class
                       }}>
              {isAuthenticated ? userNickname : 'Log In'}
            </ButtonMUI>
            <PlayNow/>
          </div>
        </div>
      </InnerContainer>
    </header>
  )
})

export default Header
