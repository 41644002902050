import { QualityTypes } from '@cloudinary/transformation-builder-sdk/types/types'
import { Cloudinary } from '@cloudinary/url-gen'
import { quality } from '@cloudinary/url-gen/actions/delivery'
import { format } from '@cloudinary/url-gen/actions/delivery'
import { auto, autoLow } from '@cloudinary/url-gen/qualifiers/quality'

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: 'somniumv3',
  },
})

const getCloudinaryUrl = (
  folder: string,
  name: string,
  qualityType: QualityTypes = 'auto'
) => {
  const folderUrl = folder ? `${folder}/` : ''
  return cloudinary
    .image(`SomniumWebsite/${folderUrl}${name}`)
    .delivery(quality(qualityType))
    .delivery(format('auto'))
    .toURL()
}

// Change all to getCloudinaryUrl
const cloudinarySource = {
  avatarMain: getCloudinaryUrl('', 'avatarMain'),
  explore: getCloudinaryUrl('web', 'explore'),
  connectAndImmerse1: getCloudinaryUrl('', 'Connect_and_Immerse_1'),
  connectAndImmerse2: getCloudinaryUrl('', 'Connect_and_Immerse_2'),
  connectSectionBG: getCloudinaryUrl('web', 'connectSectionBG', 'auto:low'),
  creatorsFundLogo: getCloudinaryUrl('web', 'creatorsFundLogo'),
  knowTheDifferentVR1Variants: getCloudinaryUrl(
    '',
    'Know_the_different_VR1_Variants'
  ),
  logo: getCloudinaryUrl('icons', 'logo'),
  main_headset:
    'https://res.cloudinary.com/somniumV3/video/upload/q_auto,f_auto/v1704791228/SomniumWebsite/main_headset.mp4',
  somniumSpacePreviewTrailer:
    'https://res.cloudinary.com/somniumV3/video/upload/q_auto:low,f_auto/v1704792068/SomniumWebsite/web/Somnium_Space_Preview_Trailer.mp4',
  somniumVR1: getCloudinaryUrl('', 'somniumVR1'),
  somniumVRLogo: getCloudinaryUrl('web', 'somniumVRLogo'),
  somniumWebLogo: getCloudinaryUrl('web', 'somniumWebLogo'),
  somniumWebWorldsLogo: getCloudinaryUrl('web', 'somniumWebWorldsLogo'),
  somniumWorldsLogo: getCloudinaryUrl('web', 'somniumWorldsLogo'),
  startingAt: getCloudinaryUrl('', 'startingAt'),
  unitySDKLogo: getCloudinaryUrl('web', 'unitySDKLogo'),
  unveilTheSpectacle1: getCloudinaryUrl(
    '',
    'Unveil_the_Spectacle_1',
    'auto:low'
  ), //still big size
  unveilTheSpectacle2: getCloudinaryUrl('', 'Unveil_the_Spectacle_2'),
  vr1bg_section: getCloudinaryUrl('', 'vr1bg_section'),
  welcome1: getCloudinaryUrl('web', 'welcome1'),
  welcome2: getCloudinaryUrl('web', 'welcome2'),
  // socials
  authencity: getCloudinaryUrl('icons', 'authencity'),
  twitter: getCloudinaryUrl('icons', 'twitter'),
  discord: getCloudinaryUrl('icons', 'discord'),
  youtube: getCloudinaryUrl('icons', 'youtube'),
  medium: getCloudinaryUrl('icons', 'medium'),
  telegram: getCloudinaryUrl('icons', 'telegram'),
  twitch: getCloudinaryUrl('icons', 'twitch'),
  reddit: getCloudinaryUrl('icons', 'reddit'),
  instagram: getCloudinaryUrl('icons', 'instagram'),
  // worlds
  SWorldBG: getCloudinaryUrl('web', 'SWorldBG'),
  MWorldBG: getCloudinaryUrl('web', 'MWorldBG'),
  XLWorldBG: getCloudinaryUrl('web', 'XLWorldBG'),
  // news
  newsCNBC: getCloudinaryUrl('web', 'newsCNBC'),
  newsTed: getCloudinaryUrl('web', 'newsTed'),
  newsYahoo: getCloudinaryUrl('web', 'newsYahoo'),
  newsVentureBeat: getCloudinaryUrl('web', 'newsVentureBeat'),
  newsCzechcrunch: getCloudinaryUrl('web', 'newsCzechcrunch'),
  newsNpr: getCloudinaryUrl('web', 'newsNpr'),
  newsCoinTelegraph: getCloudinaryUrl('web', 'newsCoinTelegraph'),
  newsForbes: getCloudinaryUrl('web', 'newsForbes'),
  newsUpload: getCloudinaryUrl('web', 'newsUpload'),
  newsCNN: getCloudinaryUrl('web', 'newsCNN'),
  newsViceTV: getCloudinaryUrl('web', 'newsViceTV'),
  // partners
  partnerBGA: getCloudinaryUrl('web', 'partnerBGA'),
  partnerGemini: getCloudinaryUrl('web', 'partnerGemini'),
  partnerLandvault: getCloudinaryUrl('web', 'partnerLandvault'),
  partnerLYNX: getCloudinaryUrl('web', 'partnerLYNX'),
  partnerOpenSea: getCloudinaryUrl('web', 'partnerOpenSea'),
  partnerPolygon: getCloudinaryUrl('web', 'partnerPolygon'),
  partnerPrushaResearch: getCloudinaryUrl('web', 'partnerPrushaResearch'),
  partnerReadyPlayerMe: getCloudinaryUrl('web', 'partnerReadyPlayerMe'),
  partnerSansar: getCloudinaryUrl('web', 'partnerSansar'),
  partnerTeslasuit: getCloudinaryUrl('web', 'partnerTeslasuit'),
  partnerVrginners: getCloudinaryUrl('web', 'partnerVrginners'),
  // PCSDK
  PCSDKImage1: getCloudinaryUrl('web', 'PCSDKImage1'),
  PCSDKImage2: getCloudinaryUrl('web', 'PCSDKImage2'),
  PCSDKImage3: getCloudinaryUrl('web', 'PCSDKImage3'),
  PCSDKImage4: getCloudinaryUrl('web', 'PCSDKImage4'),
  PCSDKImage5: getCloudinaryUrl('web', 'PCSDKImage5'),
  PCSDKImage6: getCloudinaryUrl('web', 'PCSDKImage6'),
  PCSDKImage7: getCloudinaryUrl('web', 'PCSDKImage7'),
  PCSDKImage8: getCloudinaryUrl('web', 'PCSDKImage8', 'auto:low'),
  PCSDKImage9: getCloudinaryUrl('web', 'PCSDKImage9'),
  PCSDKImage10: getCloudinaryUrl('web', 'PCSDKImage10', 'auto:low'),
  // SDK
  SDKImage1: getCloudinaryUrl('web', 'SDKImage1'),
  SDKImage2: getCloudinaryUrl('web', 'SDKImage2'),
  SDKImage3: getCloudinaryUrl('web', 'SDKImage3'),
  SDKImage4: getCloudinaryUrl('web', 'SDKImage4'),
  SDKImage5: getCloudinaryUrl('web', 'SDKImage5'),
  SDKImage6: getCloudinaryUrl('web', 'SDKImage6'),
  SDKImage7: getCloudinaryUrl('web', 'SDKImage7'),
  SDKImage8: getCloudinaryUrl('web', 'SDKImage8'),
  SDKImage9: getCloudinaryUrl('web', 'SDKImage9'),
  SDKImage10: getCloudinaryUrl('web', 'SDKImage10'),
  // withUs
  withUsGemini: getCloudinaryUrl('web', 'withUsGemini'),
  withUsPolygon: getCloudinaryUrl('web', 'withUsPolygon'),
  withUsBudweiser: getCloudinaryUrl('web', 'withUsBudweiser'),
  withUsHungama: getCloudinaryUrl('web', 'withUsHungama'),
  withUsShop: getCloudinaryUrl('web', 'withUsShop'),
  withUsTeslasuit: getCloudinaryUrl('web', 'withUsTeslasuit'),
  withUsMoca: getCloudinaryUrl('web', 'withUsMoca'),
  withUsDRESSX: getCloudinaryUrl('web', 'withUsDRESSX'),
  withUsCentury21: getCloudinaryUrl('web', 'withUsCentury21'),
  withUsCNN: getCloudinaryUrl('web', 'withUsCNN'),
  withUsNarodni: getCloudinaryUrl('web', 'withUsNarodni'),
  withUsO2: getCloudinaryUrl('web', 'withUsO2'),
  withUsReadyPlayerMe: getCloudinaryUrl('web', 'withUsReadyPlayerMe'),
  withUsKAREL: getCloudinaryUrl('web', 'withUsKAREL'),
  withUsHouseOfLobkowicz: getCloudinaryUrl('web', 'withUsHouseOfLobkowicz'),
  withUsArtInSpace: getCloudinaryUrl('web', 'withUsArtInSpace'),
  withUsMastercard: getCloudinaryUrl('web', 'withUsMastercard'),
  withUsYahoo: getCloudinaryUrl('web', 'withUsYahoo'),
  withUsCeskaTV: getCloudinaryUrl('web', 'withUsCeskaTV'),
  withUsZDF: getCloudinaryUrl('web', 'withUsZDF'),
  withUsCeskaSport: getCloudinaryUrl('web', 'withUsCeskaSport'),
}

// const serverUrl = 'https://somniumspace.com/files/multimedia/'
const serverUrl =
  'https://ai-space.fra1.cdn.digitaloceanspaces.com/website/images/Assets/'

const serverSource = {
  avatarMain: `${serverUrl}avatarMain`,
  explore: `${serverUrl}explore`, //need add
  connectAndImmerse1: `${serverUrl}connectAndImmerse1.png`,
  connectAndImmerse2: `${serverUrl}connectAndImmerse2.png`,
  connectSectionBG: `${serverUrl}connectSectionBG`,
  creatorsFundLogo: `${serverUrl}creatorsFundLogo.svg`,
  knowTheDifferentVR1Variants: `${serverUrl}knowTheDifferentVR1Variants`,
  logo: `${serverUrl}logo.svg`,
  shop: `${serverUrl}shop.svg`,
  main_headset: `${serverUrl}main_headset`,
  somniumSpacePreviewTrailer: `${serverUrl}somniumSpacePreviewTrailer`,
  somniumVR1: `${serverUrl}somniumVR1`,
  somniumVRLogo: `${serverUrl}somniumVRLogo.svg`,
  somniumWebLogo: `${serverUrl}somniumWebLogo.svg`,
  somniumWebWorldsLogo: `${serverUrl}somniumWebWorldsLogo.svg`,
  somniumWorldsLogo: `${serverUrl}somniumWorldsLogo.svg`,
  startingAt: `${serverUrl}strartingAt`,
  unitySDKLogo: `${serverUrl}unitySDKLogo.svg`,
  unveilTheSpectacle1: `${serverUrl}unveilTheSpectacle1.png`,
  unveilTheSpectacle2: `${serverUrl}unveilTheSpectacle2.png`,
  vr1bg_section: `${serverUrl}vr1bg_section`,
  welcome1: `${serverUrl}welcome1`,
  welcome2: `${serverUrl}welcome2`,
  // socials
  authencity: `${serverUrl}authencity.svg`,
  twitter: `${serverUrl}twitter.svg`,
  discord: `${serverUrl}discord.svg`,
  youtube: `${serverUrl}youtube.svg`,
  medium: `${serverUrl}medium.svg`,
  telegram: `${serverUrl}telegram.svg`,
  twitch: `${serverUrl}twitch.svg`,
  reddit: `${serverUrl}reddit.svg`,
  instagram: `${serverUrl}instagram.svg`,

  // worlds
  SWorldBG: `${serverUrl}SWorldBG`,
  MWorldBG: `${serverUrl}MWorldBG`,
  XLWorldBG: `${serverUrl}XLWorldBG`,

  // news
  newsCNBC: `${serverUrl}newsCNBC.svg`,
  newsTed: `${serverUrl}newsTed.svg`,
  newsYahoo: `${serverUrl}newsYahoo.svg`,
  newsVentureBeat: `${serverUrl}newsVentureBeat.svg`,
  newsCzechcrunch: `${serverUrl}newsCzechcrunch.svg`,
  newsNpr: `${serverUrl}newsNpr.svg`,
  newsCoinTelegraph: `${serverUrl}newsCoinTelegraph.svg`,
  newsForbes: `${serverUrl}newsForbes.svg`,
  newsUpload: `${serverUrl}newsUpload.svg`,
  newsCNN: `${serverUrl}newsCNN.svg`,
  newsViceTV: `${serverUrl}newsViceTV.svg`,
  // partners
  partnerBGA: `${serverUrl}partnerBGA.svg`,
  partnerGemini: `${serverUrl}partnerGemini.svg`,
  partnerLandvault: `${serverUrl}partnerLandvault.svg`,
  partnerLYNX: `${serverUrl}partnerLYNX.svg`,
  partnerOpenSea: `${serverUrl}partnerOpenSea.svg`,
  partnerPolygon: `${serverUrl}partnerPolygon.svg`,
  partnerPrushaResearch: `${serverUrl}partnerPrushaResearch.svg`,
  partnerReadyPlayerMe: `${serverUrl}partnerReadyPlayerMe.svg`,
  partnerSansar: `${serverUrl}partnerSansar.svg`,
  partnerTeslasuit: `${serverUrl}partnerTeslasuit.svg`,
  partnerVrginners: `${serverUrl}partnerVrginners.svg`,
  // PCSDK
  PCSDKImage1: `${serverUrl}PCSDKImage1.png`,
  PCSDKImage2: `${serverUrl}PCSDKImage2.png`,
  PCSDKImage3: `${serverUrl}PCSDKImage3.png`,
  PCSDKImage4: `${serverUrl}PCSDKImage4.png`,
  PCSDKImage5: `${serverUrl}PCSDKImage5.jpeg`,
  PCSDKImage6: `${serverUrl}PCSDKImage6.png`,
  PCSDKImage7: `${serverUrl}PCSDKImage7.png`,
  PCSDKImage8: `${serverUrl}PCSDKImage8.jpeg`,
  PCSDKImage9: `${serverUrl}PCSDKImage9.jpeg`,
  PCSDKImage10: `${serverUrl}PCSDKImage10.jpeg`,
  // SDK
  SDKImage1: `${serverUrl}SDKImage1.png`,
  SDKImage2: `${serverUrl}SDKImage2.png`,
  SDKImage3: `${serverUrl}SDKImage3.png`,
  SDKImage4: `${serverUrl}SDKImage4.png`,
  SDKImage5: `${serverUrl}SDKImage5.png`,
  SDKImage6: `${serverUrl}SDKImage6.png`,
  SDKImage7: `${serverUrl}SDKImage7.png`,
  SDKImage8: `${serverUrl}SDKImage8.png`,
  SDKImage9: `${serverUrl}SDKImage9.png`,
  SDKImage10: `${serverUrl}SDKImage10.png`,
  // withUs
  withUsGemini: `${serverUrl}withUsGemini`,
  withUsPolygon: `${serverUrl}withUsPolygon`,
  withUsBudweiser: `${serverUrl}withUsBudweiser`,
  withUsHungama: `${serverUrl}withUsHungama`,
  withUsShop: `${serverUrl}withUsShop`,
  withUsTeslasuit: `${serverUrl}withUsTeslasuit`,
  withUsMoca: `${serverUrl}withUsMoca`,
  withUsDRESSX: `${serverUrl}withUsDRESSX`,
  withUsCentury21: `${serverUrl}withUsCentury21`,
  withUsCNN: `${serverUrl}withUsCNN`,
  withUsNarodni: `${serverUrl}withUsNarodni`,
  withUsO2: `${serverUrl}withUsO2`,
  withUsReadyPlayerMe: `${serverUrl}withUsReadyPlayerMe`,
  withUsKAREL: `${serverUrl}withUsKAREL`,
  withUsHouseOfLobkowicz: `${serverUrl}withUsHouseOfLobkowicz`,
  withUsArtInSpace: `${serverUrl}withUsArtInSpace`,
  withUsMastercard: `${serverUrl}withUsMastercard`,
  withUsYahoo: `${serverUrl}withUsYahoo`,
  withUsCeskaTV: `${serverUrl}withUsCeskaTV`,
  withUsZDF: `${serverUrl}withUsZDF`,
  withUsCeskaSport: `${serverUrl}withUsCeskaSport`,
}

export type TStaticMultimedia = keyof typeof staticMultimedia

export const staticMultimedia =
  process.env.REACT_APP_MULTIMEDIA_SOURCE === 'cloudinary'
    ? cloudinarySource
    : serverSource
