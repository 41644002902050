import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { omit } from 'lodash'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MUIAccordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { IMainMenuItem, ISubMenuItem } from 'models'
import { mainMenuItems, staticMultimedia } from 'utils'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import styles from './styles.module.scss'

interface StyledAccordionProps extends AccordionProps {
  isDisabled: boolean
}

const StyledAccordion = styled((props: StyledAccordionProps) => (
  <MUIAccordion {...omit(props, 'isDisabled')} />))
(({ theme, isDisabled }) => ({
  '&.MuiAccordion-root': {
    pointerEvents: isDisabled ? 'none' : 'auto',
    margin: '0 0 10px',
    backgroundColor: 'transparent',
    color: '#ABABAB',
    boxShadow: 'none',

    '&:last-of-type': {
      marginBottom: '0'
    },

    '&:before': {
      height: 0
    },

    '&:hover:not(.Mui-expanded)': {
      background: '#131B29',
      // color: '#0CF'
      color: '#FFFFFF'
    }
  },
  '&.Mui-expanded': {
    margin: '0 0 10px',
    background: '#131B29',
    color: '#FFFFFF',

    '&:last-of-type': {
      marginBottom: '0'
    }
  }
}))

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  isHaveSubMenu: boolean
  isDisabled: boolean
}

const StyledAccordionSummary = styled((props: StyledAccordionSummaryProps) => (
  <AccordionSummary {...omit(props, 'isHaveSubMenu', 'isDisabled')} />))
(({ theme, isHaveSubMenu, isDisabled }) => ({
  '&.MuiAccordionSummary-root': {
    pointerEvents: isDisabled ? 'none' : 'auto',
    opacity: isDisabled ? 0.5 : 1,
    padding: '5px 10px',
    minHeight: '38px',

    '& .MuiAccordionSummary-expandIconWrapper': {
      display: isHaveSubMenu ? 'block' : 'none'
    },

    '& .MuiAccordionSummary-content': {
      margin: 0
    },

    '&.Mui-expanded': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: isHaveSubMenu ? 'block' : 'none'
      }
    },

    '&:hover:not(.Mui-expanded)': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        '& svg': {
          color: '#0CF'
        }
      }
    }
  }
}))

interface IProps {
}

export const MobileMenu: FC<IProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null)
  const [activeSubMenuItem, setActiveSubMenuItem] = useState<string | null>(null)

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveMenuItem(null)
      setActiveSubMenuItem(null)
    }
  }, [location.pathname])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleChangeActiveSubMenuItem = (subItem: ISubMenuItem) => {
    setActiveSubMenuItem(subItem.id)
    navigate(subItem.redirectPath)
    setAnchorElNav(null)
  }

  const handleChangeActiveMenuItem = (menuItem: IMainMenuItem) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (menuItem.subMenuList && menuItem.subMenuList.length > 0) {
      setActiveMenuItem(isExpanded ? menuItem.id : null)
    } else {
      setActiveMenuItem(menuItem.id)
      if (menuItem.redirectPath) {
        if (menuItem.redirectPathNewWindow) {
          window.open(menuItem.redirectPathNewWindow, '_blank', 'noopener,noreferrer')
        } else {
          navigate(menuItem.redirectPath)
        }
      }

      setAnchorElNav(null)
    }
  }


  return (
    <Box sx={{
      flexGrow: 1,
      display: { xs: 'flex', md: 'none' },
      alignItems: 'center'
    }}>
      <IconButton
        size="large"
        aria-label="documentation menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        sx={{ padding: '10px 15px' }}
      >
        <MenuIcon sx={{ color: 'white' }}/>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          '.MuiMenu-paper': {
            background: 'transparent',
            borderRadius: '10px',
            color: 'white',
            maxWidth: 'calc(100% - 40px)'
          },
          '.MuiMenu-list': {
            padding: 0
          }
        }}
      >

        <Box className={styles.menu}>
          {mainMenuItems.map(menuItem => {
            return (
              <StyledAccordion expanded={activeMenuItem === menuItem.id}
                               onChange={handleChangeActiveMenuItem(menuItem)}
                               isDisabled={Boolean(menuItem.disabled)}
                               key={menuItem.id}
              >
                <StyledAccordionSummary
                  isHaveSubMenu={Boolean(menuItem.subMenuList && menuItem.subMenuList?.length > 0)}
                  isDisabled={Boolean(menuItem.disabled)}
                  expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }}/>}
                  aria-controls={`panel${menuItem.id}-content`}
                  id={`panel${menuItem.id}-header`}
                >
                  <div className={styles.chapterTitleBlock}>
                    <div className={styles.title}>{menuItem.text}</div>
                  </div>
                </StyledAccordionSummary>
                {menuItem.subMenuList && menuItem.subMenuList?.length > 0
                  ? <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: '0 10px 6px' } }}>
                    {menuItem.subMenuList?.map(subItem => {
                      return (
                        <div key={subItem.id}
                             onClick={() => handleChangeActiveSubMenuItem(subItem)}
                             className={cn({
                               [styles.subMenuItem]: true,
                               [styles.subMenuItemActive]: subItem.id === activeSubMenuItem
                             })}>
                          {subItem.text}
                        </div>
                      )
                    })}
                  </AccordionDetails>
                  : null}
              </StyledAccordion>
            )
          })}
        </Box>
      </Menu>
      <Link to={'/'} className={styles.logo}>
        <img src={staticMultimedia.logo} alt="logo"/>
      </Link>
    </Box>
  )
}
