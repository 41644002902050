import React, { FC, lazy, Suspense } from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import { ROUTES } from 'utils'

import Loading from 'components/Loading'
import PrivateRoutes from 'components/PrivateRoutes'

const Main = lazy(() => import('pages/Main'))
const Auth = lazy(() => import('pages/Auth'))
const OpenAPI = lazy(() => import('pages/OpenAPI'))
const Documentation = lazy(() => import('pages/Documentation'))
const LicenseAgreement = lazy(() => import('pages/LicenseAgreement'))
const Profile = lazy(() => import('pages/Profile'))
const ConfirmAlpha = lazy(() => import('pages/ConfirmAlpha'))
const Overlay = lazy(() => import('pages/Overlay'))
const ResetPasswordBeta = lazy(() => import('pages/Auth/ResetPasswordBeta'))
const Events = lazy(() => import('pages/Events'))
const EventInfo = lazy(() => import('pages/Events/EventInfo'))
const News = lazy(() => import('pages/News'))
// const Map = lazy(() => import('pages/Map'))

export const RouteContainer: FC = () => {
  return (
    <Suspense fallback={<Loading active />}>
      <Routes>
        <Route path={ROUTES.MAIN} element={<Main />} />
        <Route path={ROUTES.AUTH} element={<Auth />} />
        <Route path={ROUTES.CONFIRM_ALPHA} element={<ConfirmAlpha />} />
        <Route path={ROUTES.OPENAPI} element={<OpenAPI />} />
        <Route path={ROUTES.OPENAPI_OPENED} element={<OpenAPI />} />
        <Route path={ROUTES.DOCUMENTATION} element={<Documentation />} />
        <Route path={ROUTES.DOCUMENTATION_OPENED} element={<Documentation />} />
        <Route path={ROUTES.LICENSE_AGREEMENT} element={<LicenseAgreement />} />
        <Route path={ROUTES.OVERLAY} element={<Overlay />} />
        <Route path={ROUTES.AUTH_BETA} element={<Auth />} />
        <Route
          path={ROUTES.RESET_PASSWORD_BETA}
          element={<ResetPasswordBeta />}
        />
        <Route path={ROUTES.EVENTS} element={<Events />} />
        <Route path={ROUTES.EVENT_INFO} element={<EventInfo />} />
        <Route path={ROUTES.NEWS} element={<News />} />
        {/*<Route path={ROUTES.MAP} element={<Map/>}/>*/}
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTES.PROFILE} element={<Profile />} />
        </Route>
        <Route path={'*'} element={<Navigate to={ROUTES.MAIN} replace />} />
      </Routes>
    </Suspense>
  )
}
