import React from 'react'

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

import { useMediaQuery } from 'utils/hooks'

import Download from 'assets/images/download.svg'
import Play from 'assets/images/play.svg'

interface InteractionProps {}

export default function PlayNow(props: InteractionProps) {
  const width_430 = useMediaQuery('(max-width: 430px)')

  const playNowItem = (title: string, subtitle: string) => (
    <div
      style={{
        color: '#FFF',
        fontFamily: 'Exo, sans-serif',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textAlign: 'start',
      }}
    >
      <div>{title}</div>
      <div style={{ fontSize: '0.625rem', fontWeight: 500 }}>{subtitle}</div>
    </div>
  )

  const playButtonStyle = width_430
    ? { width: '36px', marginLeft: '15px', '& > span': { margin: 0 } }
    : {}

  return (
    <PopupState variant="popover" popupId="popup-menu">
      {popupState => (
        <React.Fragment>
          <Button
            {...bindTrigger(popupState)}
            variant={'outlined'}
            startIcon={
              <img
                style={{ width: '13px', height: '13px' }}
                src={Play}
                alt="play"
              />
            }
            sx={{
              height: '36px',
              width: '115px',
              minWidth: '36px',
              color: '#FFF',
              fontFamily: 'Exo, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'none',
              borderRadius: '25px',
              background: 'transparent',
              border: '2px solid #17E547',
              marginLeft: '20px',
              ...playButtonStyle,

              '&:hover': {
                border: '2px solid #17E547',
                backgroundColor: '#007F1E',
                textShadow: '0 3px 5px rgba(0, 0, 0, 0.25)',
              },
              '&:active': {
                border: '2px solid #17E547',
                backgroundColor: '#73E58E',
              },
            }}
          >
            {width_430 ? '' : 'Play now'}
          </Button>
          <Menu
            {...bindMenu(popupState)}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              sx: {
                padding: 0,
                display: 'flex',
              },
            }}
            slotProps={{
              paper: {
                sx: {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: 0,
                  boxShadow: 'none',
                  marginTop: '12px',
                  padding: '10px',
                  overflow: 'visible',
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                popupState.close()
                window.open(
                  'https://somniumspace.com/launcher/Somnium%20Space.exe',
                  '_self',
                  'noopener,noreferrer'
                )
              }}
              component={Button}
              variant="contained"
              startIcon={
                <img
                  style={{ width: '15px', height: '15px' }}
                  src={Download}
                  alt="download"
                />
              }
              size="small"
              sx={{
                background: '#007A99',
                borderRadius: '5px',
                color: '#FFFFFF',
                height: 40,
                textTransform: 'capitalize',
                transition: 'transform .2s',
                padding: '5px 13px',
                marginRight: '20px',

                '&:hover': {
                  background: '#00CCFF',
                },

                '&:active': {
                  background: '66E0FF',
                },

                '& span': {
                  margin: '0 10px 0 0',
                },
              }}
            >
              {playNowItem('Download', 'Somnium Launcher')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                window.open(
                  'https://somniumspace.com/parcel/',
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
              component={Button}
              variant="contained"
              startIcon={
                <img
                  style={{ width: '13px', height: '15px' }}
                  src={Play}
                  alt="play"
                />
              }
              size="small"
              sx={{
                background: '#993D00',
                borderRadius: '5px',
                color: '#FFFFFF',
                height: 40,
                textTransform: 'capitalize',
                transition: 'transform .2s',
                padding: '5px 13px',

                '&:hover': {
                  background: '#FF6600',
                },

                '&:active': {
                  background: 'FFA366',
                },

                '& span': {
                  margin: '0 10px 0 0',
                },
              }}
            >
              {playNowItem('Launch', 'Somnium Web Client')}
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
