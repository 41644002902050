import React from 'react'
import { BrowserRouter } from 'components/BrowserRouter'
import Loading from 'components/Loading'
import { RouteContainer } from 'containers/RouteContainer'
import { AppContainer } from 'containers/AppContainer'
import { Toast } from 'components/Toast'
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  console.log('ENVIRONMENT:', process.env.REACT_APP_ENVIRONMENT)
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading active/>} //If not pass, nothing appears at the time of new version check.
      // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        <BrowserRouter>
          <AppContainer>
            <RouteContainer/>
          </AppContainer>
        </BrowserRouter>
        <Loading/>
        <Toast/>
      </React.Fragment>
    </CacheBuster>
  )
}

export default App
